.App{
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color:#10182F;
}

.topSide{
  height:50vh;
  background-color: #F7F8FC;
  display:flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
}

.bottomSideLanding{
  height:50vh;
  background-color: #ffffff;
  display:flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: flex-start;
  padding-left:50px;
  padding-top:100px;
  box-sizing: border-box;
}

.bottomSide{
  height:50vh;
  background-color: #ffffff;
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  box-sizing: border-box;
}

.landingBrand{
  font-size: 24px;
  font-weight: bold;
  color: #10182F;
  letter-spacing: -3%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:5px;
}

.landingPerformance{
  max-width:70vw;
  margin-top:20px;
  text-align:center;
}

.getStartedButton{
  background-color: #10182F;
  color: #ffffff;
  padding: 15px 50px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top:50px;
  transition: 0.3s ease-in-out;
  border:2px solid #10182f00;

}

.cashOutButton{
  background-color: #10182F;
  color: #ffffff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border:2px solid #10182f00;
  padding:10px 15px;
}

.cashOutButton:hover{
  background-color: #ffffff;
  color: #10182F;
  border:2px solid #10182F;
}


.getStartedButton:hover{
  background-color: #ffffff00;
  color: #10182F;
  border:2px solid #10182F;
}

.getStartedButtonExplainer{
  max-width:50vw;
  margin-top:5px;
  text-align:center;
  font-size:13px;
}

.landingListItem{
  display:flex;
  margin-bottom:10px;
  align-items: flex-start;
  justify-content: center;
  gap:10px;
  font-weight:700;
  font-size:16px;
  position:relative;
  left:-400px;
}

.landingListItem{
  animation: splash 1s normal forwards ease-in-out;
  -webkit-animation: splash 1s normal forwards ease-in-out;
  -moz-animation: splash 1s normal forwards ease-in-out;
  -o-animation: splash 1s normal forwards ease-in-out;
  -ms-animation: splash 1s normal forwards ease-in-out;
}

@keyframes splash {
    from {
        opacity: 0;
        left: -400px;
    }
    to {
        opacity: 1;
        left:0;
    }
}

.opacityanimate{
  animation: opacityanimation 1s normal forwards ease-in-out;
  -webkit-animation: opacityanimation 1s normal forwards ease-in-out;
  -moz-animation: opacityanimation 1s normal forwards ease-in-out;
  -o-animation: opacityanimation 1s normal forwards ease-in-out;
  -ms-animation: opacityanimation 1s normal forwards ease-in-out;
}

@keyframes opacityanimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.socialimage{
  opacity:0;
  transition:0.2s;
  cursor:pointer;
}

.socialimage:hover{
  transform: scale(1.1);
}


.scaleanimate{
  animation: scaleanimation 1s normal forwards ease-in-out;
  -webkit-animation: scaleanimation 1s normal forwards ease-in-out;
  -moz-animation: scaleanimation 1s normal forwards ease-in-out;
  -o-animation: scaleanimation 1s normal forwards ease-in-out;
  -ms-animation: scaleanimation 1s normal forwards ease-in-out;
}

@keyframes scaleanimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.landingTick{
  opacity:0.9;
}

.socials{
  position:fixed;
  bottom:20px;
  right:20px;
  display: flex;
  gap:10px;
}

.yourBalance{
  font-size:20px;
  font-weight:600;
}

.balanceNumber{
  font-size:72px;
  font-weight:700;
}

.seePerformanceButton{
  background-color: #ffffff;
  color: #10182F;
  border:2px solid #10182F;
  padding: 15px 50px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top:20px;
  width:150px;
  text-align: center;
}

.generalButton{
  background-color: #10182F;
  color: #ffffff;
  padding: 15px 50px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width:150px;
  text-align: center;
}



@media screen and (min-width: 768px) {
  .AppDesktop{
    display:flex;
    justify-content:center;
    align-items:center;
    height:100vh;
  }
  .App{
    width:50vw;
    box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.1);
  }
  .topSide{
    height:40vh;
  }
  .bottomSide{
    height:40vh;
  }
  .bottomSideLanding{
    height:40vh;
  }
  .landingListItem{
    opacity:0;
    animation: opacityanimation 1s normal forwards ease-in-out;
    -webkit-animation: opacityanimation 1s normal forwards ease-in-out;
    -moz-animation: opacityanimation 1s normal forwards ease-in-out;
    -o-animation: opacityanimation 1s normal forwards ease-in-out;
    -ms-animation: opacityanimation 1s normal forwards ease-in-out;
    left:50px;
  }
}

.animNum{
  transition:0.3s;
}

.animNum:hover{
  transform:scale(1.05);
}

.profit{
  background: #525f80;
  color: #ffffff;
  padding: 3px 5px;
  border-radius: 5px;
  font-weight: 700;
}

.balanceExplainer{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:5px;
}

.modalCard {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 30vw;
  background: white;
  border-radius: 10px;
  border: none;
  outline: none;
  overflow: hidden;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.3);
  animation: scaler 0.3s normal forwards ease-in-out;
  -webkit-animation: scaler 0.3s normal forwards ease-in-out;
  -moz-animation: scaler 0.3s normal forwards ease-in-out;
  -o-animation: scaler 0.3s normal forwards ease-in-out;
  -ms-animation: scaler 0.3s normal forwards ease-in-out;
}

@keyframes scaler {
  from {
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    transform: translate(-50%, -50%) scale(1);
  }
}


@media screen and (max-width: 768px) {
  .modalCard{
    width: 90vw;
  }
}

.modalTopside{
  background-color: #F7F8FC;
  display:flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  padding:30px;
  box-sizing: border-box;
  font-size:22px;
  font-weight:500;
}

.modalBottomside{
  background-color: #ffffff;
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items: flex-start;
  box-sizing: border-box;
  padding:30px;
  gap:10px;
}

.cashoutAvailable{
  font-size: 13px;
  opacity:0.8;
}